html,
body {
  margin: 0;
  box-sizing: border-box;
}

.react-flow .react-flow__handle {
  width: 25px;
  height: 12px;
  border-radius: 3px;
  background-color: rgb(0, 95, 97);
  margin: 0;
  padding: 0;
}


.edgebutton {
  width: 20px;
  height: 20px;
  background: #eee;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  line-height: 1;
}
 
.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}