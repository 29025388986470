.swiper {
  width: 100%;
  height: auto;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mySwiper {
  width: 100%;
  height: 100vh;
  display: flex;
  border-radius: 1em;
}